import React, {createContext, useContext, useState} from "react";
import { jsonHeader, multiFormHeader } from "../apis/header";
import ApiTemplate from "../apis/api_template";

const QuestionsContext = createContext();

export const QuestionsContextProvider = ({children}) =>{
    const [allQuestions, setAllQuestions]=useState(null);
    const [postQuestion,setPostQuestion]=useState(null);
    const [postSuccess, setPostSuccess]=useState(false);

    const getQuestions = async (token) => {
        try {
            const headers = multiFormHeader(token)

            const response = await ApiTemplate("get", "/api/v1/search_parameter", {}, headers);
            if (response && response["success"] === true) {
                setAllQuestions(response["data"]);
            } else {
                console.log("response :", response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const questionFilterSave= async (token, questionsData) =>{
        setPostQuestion(true);
        try {
            const headers=jsonHeader(token);
            const response = await ApiTemplate("post", '/api/v1/event/filter_save', questionsData , headers);
            if(response && response["success"] === true){
                setPostQuestion(false);
                setPostSuccess(true)
            }else{
                console.log("Response message :", response.message)
            }
        } catch (error) {
            console.error("Error during API call:", error);
            setPostQuestion(false);
            setPostSuccess(false);
        }
    }

    return (
        <QuestionsContext.Provider
        value={{
            allQuestions,
            getQuestions,
            questionFilterSave,
            setPostQuestion,
            postQuestion,
            postSuccess,
            setPostSuccess
        }}
        >
            {children}
        </QuestionsContext.Provider>
    )
}

export const useQuestionsContext = () =>{
    const questionContextValue = useContext(QuestionsContext);
    return questionContextValue;
}