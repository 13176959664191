import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar

  const menuItems = [
    {
      label: "Dashboard",
      icon: "icon_1.svg",
      route: "/",
      subItems: [],
    },
    {
      label: "Events",
      icon: "icon_2.svg",
      subItems: [
        {
          label: "Create Event",
          icon: "sub_icon_2.svg",
          route: "create-event",
        },
        {
          label: "All Events",
          icon: "sub_icon_3.svg",
          route: "all-events",
        },
        {
          label: "Booked Events",
          icon: "sub_icon_3.svg",
          route: "booked-events",
        },
      ],
    },
    {
      label: "Membership Plans",
      icon: "subscription_icon.svg",
      route: "subscription-plans",
      subItems: [],
    },
    {
      label: "Location",
      icon: "icon_3.svg",
      route: "location",
      subItems: [],
    },
    {
      label: "Categories",
      icon: "icon_7.svg",
      route: "categories",
      subItems: [],
    },
    {
      label: "Coordinators",
      icon: "icon_8.svg",
      route: "coordinators",
      subItems: [],
    },
    {
      label: "Blogs",
      icon: "icon_4.svg",
      route: "blogs",
      subItems: [],
    },
    {
      label: "Users",
      icon: "icon_5.svg",
      route: "users",
      subItems: [],
    },
    {
      label: "Setting",
      icon: "icon_6.svg",
      subItems: [
        {
          label: "Icons",
          icon: "sub_icon_2.svg",
          route: "icons",
        },
        {
          label: "App Media",
          icon: "sub_icon_2.svg",
          route: "app-media",
        },
        {
          label: "Contact Support",
          icon: "sub_icon_2.svg",
          route: "queries",
        },
      ],
    },
    {
      label: "Finance",
      icon: "mdi_finance.svg",
      route: "finance",
      subItems: [],
    },
    {
      label: "Ledger Reports",
      icon: "ledger.svg",
      route: "ledger-reports",
      subItems: [],
    },
  ];

  return (
    <div className="relative">
      {/* Sidebar Toggle Button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`absolute ${
          isSidebarOpen ? "left-52 top-4" : "left-1 top-6"
        } z-50 p-2 rounded-full`}
      >
        <RxHamburgerMenu size={25} />
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-white shadow-lg border-r transition-transform duration-300 z-40 ${
          isSidebarOpen ? "translate-x-0 w-64" : "-translate-x-full w-64"
        }`}
      >
        {/* Logo */}
        <div className="flex items-center pl-6 space-x-3 text-lg font-bold mt-5">
          <img
            src="/images/sidebar/logo.svg"
            className={`${!isSidebarOpen && "w-7"}`}
            alt="Logo"
          />
        </div>

        <div className="mt-6 overflow-y-auto max-h-screen scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          <ul className="flex flex-col space-y-2 text-sm text-gray-300 pb-10">
            {menuItems.map((menuItem, key) => (
              <SidebarItem
                key={key}
                menuItem={menuItem}
                isSidebarOpen={isSidebarOpen}
              />
            ))}
          </ul>
        </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

const SidebarItem = ({ menuItem, isSidebarOpen }) => {
  const [isShowSubItems, setIsShowSubItems] = useState(false);

  return (
    <>
      <div>
        <Link
          to={menuItem.route}
          onClick={() => {
            if (menuItem.subItems.length > 0) {
              setIsShowSubItems(!isShowSubItems);
            }
          }}
          className={`flex items-center gap-5 px-6 py-3 rounded-lg cursor-pointer hover:bg-green-200 ${
            isShowSubItems ? "bg-green-200" : ""
          }`}
        >
          <img
            src={`/images/sidebar/${menuItem.icon}`}
            alt={`${menuItem.label} Icon`}
            className="w-6"
          />
          {isSidebarOpen && (
            <span className="text-black">{menuItem.label}</span>
          )}
          {menuItem.subItems.length > 0 && isSidebarOpen && (
            <img
              src="/images/sidebar/arrow_1.svg"
              alt="Toggle Subitems"
              className={`ml-auto transform transition-transform ${
                isShowSubItems ? "rotate-90" : ""
              }`}
            />
          )}
        </Link>
      </div>
      {isShowSubItems && menuItem.subItems.length > 0 && (
        <ul className={`flex flex-col space-y-2 ml-8 mt-2`}>
          {menuItem.subItems.map((subItem, subKey) => (
            <Link key={subKey} to={subItem.route}>
              <li className="flex items-center gap-3 px-4 py-2 rounded-lg hover:bg-green-200">
                <img
                  src={`/images/sidebar/${subItem.icon}`}
                  alt={`${subItem.label} Icon`}
                  className="w-5"
                />
                {isSidebarOpen && (
                  <span className="text-black">{subItem.label}</span>
                )}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
