import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { Link } from 'react-router-dom';


function AddAccomodation({ onNext }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [accommodationFields, setAccommodationFields] = useState([
    { no_of_persons: '', type: '', price: '' },
  ]);


  const { addEventAccomodation, addEventId } = useEventContext()
  const { token } = useAuthContext();

  const addAccommodationField = () => {
    setAccommodationFields([
      ...accommodationFields,
      { no_of_persons: '', type: '', price: '' },
    ]);
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...accommodationFields];
    updatedFields[index][fieldName] = value;
    setAccommodationFields(updatedFields);
  };

  const displayData = async () => {
    // Display data in the required format
    setLoading(true);
    const isAnyFieldEmpty = accommodationFields.some(
      (field) =>
        field.no_of_persons === '' || field.type === '' || field.price === ''
    );

    if (isAnyFieldEmpty) {
      // Set error message and prevent further action
      setError('Please fill all fields.');
      setLoading(false);
      return;
    }

    if (!addEventId) {
      setError('Please add event first.');
      setLoading(false);
      return;
    }

    let newAccomodationFields = accommodationFields.map(field => {
      return { ...field, event_id: addEventId }
    })

    const response = await addEventAccomodation(token, JSON.stringify({ accomodations: newAccomodationFields, }));
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    else {
      onNext();
    }
    setLoading(false);



  };
  return (
    <section className='w-full p-4 rounded-md  '>
      <section className='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
        {/* {{-- title --}} */}
        <div className='flex space-x-3'>
          
          <h1 className='font-semibold text-md'>Add Accommodation</h1>
        </div>
        {/* {{-- form  --}} */}
        <div className='flex flex-col space-y-6 mt-5'>
          <div id='accommodationFields' className=''>
            {/* {{-- no of persons and type --}} */}
            {accommodationFields.map((field, index) => (
              <div key={index} className='flex items-center w-full space-x-8 mb-5'>
                <div className='relative border rounded-md border-black px-2 py-4 w-1/3'>
                  <input
                    type='text'
                    value={field.no_of_persons}
                    className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'

                    onChange={(e) => handleFieldChange(index, 'no_of_persons', e.target.value)}
                  // Add other necessary input attributes
                  />
                  <p className='absolute -top-3 left-5 bg-white px-2'>No. of Persons</p>
                </div>
                <div className='relative border rounded-md border-black px-2 py-4 w-1/3'>
                  <input
                    type='text'
                    value={field.type}
                    className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'

                    onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                  // Add other necessary input attributes
                  />
                  <p className='absolute -top-3 left-5 bg-white px-2'>Type</p>
                </div>
                <div className='relative border rounded-md border-black px-2 py-4 w-1/3'>
                  <input
                    type='text'
                    value={field.price}
                    className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'

                    onChange={(e) => handleFieldChange(index, 'price', e.target.value)}
                  // Add other necessary input attributes
                  />
                  <p className='absolute -top-3 left-5 bg-white px-2'>Price</p>
                </div>
              </div>
            ))}

          </div>
          {/* {{-- add accommodation button --}} */}
          <div className='flex items-center justify-end  w-full space-x-8'>
            <button
              className='border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B] text-md font-normal'
              onClick={addAccommodationField}
            >
              + Add Accommodation
            </button>
          </div>

          {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}

          {/* {{-- cancel and save button --}} */}
          <div className='flex items-center justify-center  w-full space-x-8'>
            <Link to={"/all-events"} >
              <button className='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
                Cancel
              </button>
            </Link>
            <button
              onClick={displayData}
              className='px-16 py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'

            >
              {loading
                ? <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
                : 'Save'}
            </button>
          </div>
        </div>
      </section>
    </section>
  )
}

export default AddAccomodation
