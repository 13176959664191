import React, {createContext, useContext, useEffect, useState} from 'react';
import { jsonHeader } from '../apis/header';
import ApiTemplate from '../apis/api_template';

const SubscriptionContext =  createContext();

export const SubscriptionContextProvider = ({children}) =>{
  const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')));
  const [plansList, setPlansList] = useState(null);
  const [isPlansGet, setIsPlansGet] = useState(null);
  const [isSinglePlan, setIsSinglePlan] = useState(false);
  const [individualPlanData, setIndividualPlanData]= useState(null);

  const getAllPlans = async () =>{
    setIsPlansGet(true);
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('get', '/api/v1/subscriptionplans', {} , headers);
      if(response && response["success"] ===  true){
        setPlansList(response?.data);
        setIsPlansGet(false);
      }
    }catch(error){
      console.error("Somthing goes wrong! :", error.message);
      setIsPlansGet(false);
    }
  }

  const addPlan = async (payload) =>{
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('post', '/api/v1/subscriptionplans/store', payload , headers);
      if(response && response["success"] === true){
        await getAllPlans()
      }
    }catch(error){
      console.log("Error :", error);
      console.log("something went wrong!");
    }
  }


  const getIndividualPlan =async (id) =>{
    setIsSinglePlan(true);
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('get', `/api/v1/subscriptionplans/single/${id}`, {} , headers);
      if(response && response["success"] ===  true){
        setIndividualPlanData(response?.data);
        setIsSinglePlan(false);
      }
    }catch(error){
      console.error("Somthing goes wrong! :", error.message);
      setIsSinglePlan(false);
    }
  }

  const deletePlan = async (id) =>{
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('post', `/api/v1/subscriptionplans/delete/${id}`, {} , headers);
      if(response && response["success"] === true){
        await getAllPlans()
      }
    }catch(error){
      console.log("Error :", error);
      console.log("something went wrong!");
    }
  }

  const updatePlan = async (id, upadtedData) =>{
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('post', `/api/v1/subscriptionplans/edit/${id}`, upadtedData , headers);
      if(response && response["success"] === true){
        await getAllPlans()
      }
    }catch(error){
      console.log("Error :", error);
      console.log("something went wrong!");
    }
  }


  // useEffect(()=>{
  //   if(window.location.pathname.includes('/subscription-plans')){
  //     getAllPlans();
  //   }
  // },[])
  
  return (
    <SubscriptionContext.Provider value={{
      plansList,
      isPlansGet,
      deletePlan,
      addPlan,
      getIndividualPlan,
      isSinglePlan,
      individualPlanData,
      updatePlan,
      getAllPlans
      }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscriptionContext = () => {
  const subscriptionContextValue = useContext(SubscriptionContext);
  return subscriptionContextValue;
};