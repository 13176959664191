// EventContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";

const IconsContext = createContext();

export const IconsContextProvider = ({ children }) => {
    const [allIcons, setAllIcons] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [limit, setLimit] = useState(10); 

    const getIcons = async (page = currentPage, itemsPerPage = limit) => {
        const token = JSON.parse(localStorage.getItem('token'))
        try {
            const headers = jsonHeader(token)

            const response = await ApiTemplate("get", `/api/v1/icons?page=${page}&limit=${itemsPerPage}`, {}, headers);
            if (response && response["success"] === true) {
                setAllIcons(response["data"].data);
                setPageCount(response?.data?.last_page)
                setLimit(response?.data?.per_page)
            } else {
                console.log(response["message"], response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const addIcon = async (token, eventData) => {
        try {
            const headers = {
                "Content-Type": "multipart/form-data;",
                "X-CSRF-TOKEN": document.head
                    .querySelector('meta[name="csrf-token"]')
                    ?.getAttribute("content"),
                Authorization: `Bearer ${token}`,
            };

            const response = await ApiTemplate("post", "/api/v1/icon", eventData, headers);
            if (response && response["success"] === true) {
                await getIcons(token)
                return true;
            } else {
                console.log(response["message"], response.message);
                return false;

            }
        } catch (error) {
            console.error("Error during API call:", error);
            return false;

        }
    };


    const deleteIcon = async (token, iconId) => {
        try {
            const headers = jsonHeader(token);
      
            const response = await ApiTemplate("get", "/api/v1/remove/icon/"+iconId, {}, headers);
            if (response && response["success"] === true) {
              
              await getIcons(token);
              return true;
            } else {
              console.log(response["message"], response.message);
              return false;
      
            }
          } catch (error) {
            console.error("Error during API call:", error);
            return false;
      
          }
    };

    const updateIcon = async (token, iconId, iconData) => {
        try {
            const headers = multiFormHeader(token);
      
            const response = await ApiTemplate("post", "/api/v1/update/icon/"+iconId, iconData, headers);
            if (response && response["success"] === true) {
              
              await getIcons(token);
              return true;
            } else {
              console.log(response["message"], response.message);
              return false;
      
            }
          } catch (error) {
            console.error("Error during API call:", error);
            return false;
      
          }
    };

    return (
        <IconsContext.Provider
            value={{
                allIcons,
                getIcons,
                addIcon,
                deleteIcon,
                updateIcon,
                currentPage,
                setCurrentPage,
                pageCount,

            }}
        >
            {children}
        </IconsContext.Provider>
    );
};

export const useIconsContext = () => {
    const iconsContextValue = useContext(IconsContext);
    return iconsContextValue;
};
