import React, { useState } from 'react'
import { useReportsContext } from '../../contexts/ReportsContextProvider';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ClickAwayListener from 'react-click-away-listener';

const ReportsList = () => {
  const {financialReports} = useReportsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleItem,setSingleItem] = useState(null);

  const openModal = (user) =>{
    setIsModalOpen(true);
    setSingleItem(user)
  }

  const closeModal = () =>{
    setIsModalOpen(false);
    setSingleItem(null);
  }

  return (
    <div className="relative overflow-hidden bg-white  dark:bg-gray-800 sm:rounded-md mt-3">
          <h1 className="font-semibold text-3xl px-4 py-4">Ledger reports</h1>

          {financialReports?.length > 0 ? (
    <div
      className={`overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-gray-200 ${
        financialReports.length > 20 ? "max-h-96" : ""
      }`}
    >
            
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Booked on
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 flex items-center justify-center"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {financialReports?.map((fuser, index) => (
                  <tr
                    key={index}
                    className={`border-b dark:border-gray-700 ${
                      fuser?.is_seen === 1 ? "bg-green-200" : ""
                    }`}
                  >
                    <td className="px-4 py-3">{fuser?.user.name}</td>
                    <td className="px-4 py-3">{fuser.user.email}</td>
                    <td className="px-4 py-3">{fuser?.plan?.price}</td>
                    <td className="px-4 py-3">{format(fuser?.created_at, "yyyy-MM-dd")}</td>
                    <td className="px-2 py-3">
                      <ul className="flex items-center justify-center py-1 text-sm text-gray-700 dark:text-gray-200">
                        <li className="flex gap-3 align-middle">
                          {/* <Link
                            to={`/finance/single/${fuser.unique_string}`}
                            target="_blank"
                          >
                            <FaExternalLinkSquareAlt
                              size={20}
                              className="cursor-pointer"
                            />
                          </Link> */}
                          <button 
                          onClick={() => openModal(fuser)}
                          >
                            <img src={"images/sidebar/Show.svg"} alt="Show" />
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>):(
    <p className="text-center text-gray-500">No financial reports available.</p>
  )}

          {/* Pagination */}
          {/* <div className="flex justify-center bg-[#8DC67B]">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              forcePage={currentPage - 1}
            />
          </div> */}

       {isModalOpen && (
        <div className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 shadow-lg">
          {singleItem && (
            <ClickAwayListener onClickAway={closeModal}>

            <div
              // ref={modalRef}
              className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto"
            >
              <button
                onClick={closeModal}
                className="absolute text-4xl font-semibold top-2 right-2 text-gray-500 hover:text-black dark:text-gray-400"
              >
                &times;
              </button>
              {/* userImage */}
              {/* <div className="userImage mb-2">
                <img
                  className="w-52 rounded-lg"
                  src={
                    selectedUserId.profile_picture
                      ? `${baseUrl}${selectedUserId.profile_picture}`
                      : ""
                  }
                  alt="not found"
                />
              </div> */}
              {/* Username */}
              <div className="username mb-2">
                <h2 className="text-lg font-semibold">Full Name</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.user?.name}
                </p>
              </div>

              {/* Email */}
              <div className="useremail mb-2">
                <h2 className="text-lg font-semibold">Email</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.user?.email}
                </p>
              </div>

              {/* Gender */}
              <div className="userGender mb-2">
                <h2 className="text-lg font-semibold">Phone</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.user?.phone}
                </p>
              </div>

              {/* Country */}
              <div className="userCountry mb-2">
                <h2 className="text-lg font-semibold">Address</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.user?.address}
                </p>
              </div>

              {/* DOB */}
              <div className="userdob mb-2">
                <h2 className="text-lg font-semibold">Booking Details</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.booking_details}
                </p>
              </div>

              {/* Age */}
              <div className="userAge mb-2">
                <h2 className="text-lg font-semibold">Price</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.price}
                </p>
              </div>

              {/* Phone */}
              <div className="userPhone mb-2">
                <h2 className="text-lg font-semibold">Booked on</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {format(singleItem?.created_at, "yyyy-MM-dd")}
                </p>
              </div>

              {/* Employment Status */}
              {/* <div className="useremployment mb-2">
                <h2 className="text-lg font-semibold">Employment Status</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {selectedUserId?.emp_status}
                </p>
              </div> */}

              {/* profession */}
              <div className="userprofession mb-2">
                <h2 className="text-lg font-semibold">Booked by</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.booking_type}
                </p>
              </div>

              {/* Annual gross income */}
              <div className="userannualgrossincome mb-2">
                <h2 className="text-lg font-semibold">Total Persons</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleItem?.plan?.no_of_persons}
                </p>
              </div>

              {/* Academic Degrees */}
              {/* <div className="useracademic mb-2">
                <h2 className="text-lg font-semibold">Academic Degrees</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {JSON.parse(selectedUserId?.degree).map((val, index)=>(
                    <p>{index + 1}{") "}{val}</p>
                  ))}
                </p> */}
                {/* <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">2: Master’s</p>
        <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">3: PhD</p> */}
              </div>
            </ClickAwayListener>

              // {/* Social Media */}
              // {selectedUserId.social_media_link !== null && <div className="usersocialmedia mb-2">
              //   <h2 className="text-lg font-semibold">Social Media</h2>
              //   <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
              //     <a href={selectedUserId?.social_media_link} target="_blank">
              //       {selectedUserId?.social_media_link}
              //     </a>
              //   </p>
              // </div>}

              // {/* Package url */}
              // <div className="userpackageurl mb-2">
              //   <h2 className="text-lg font-semibold">Package</h2>
              //   <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
              //     <a href={selectedUserId?.pkg_link} target="_blank">
              //       {selectedUserId?.pkg_link}
              //     </a>
              //   </p>
              // </div>

      
            // </div>
          )}
        </div>
      )}
        </div>
  )
}

export default ReportsList