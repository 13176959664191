import React, { useEffect, useState } from 'react'
import { useCoordinatesContext } from '../../contexts/CoordinatesContextProvider'
import { ThreeDots } from 'react-loader-spinner';

const EditCoordinator = ({setShowModal, showModal, setEditCoordinator, editCoordinator}) => {
  const {updateCoordinate} = useCoordinatesContext();
  const [formData, setFormData] = useState({
    name:'',
    profession: '',
    address: '',
    description: '',
});
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');


  const handleInputChange = (e) =>{
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
  }

  const handleUpdateCoordinate = async () => {
    setLoading(true);
    setError(''); // Clear previous errors

    // Check for required fields
    if (!formData.name || !formData.profession || !formData.address || !formData.description) {
        setError('Please fill in all fields.');
        setLoading(false);
        return;
    }

    await updateCoordinate(editCoordinator.id, formData);

    // try {
    //     // Perform API call
    //     const response = await updateCoordinate(editCoordinator.id, formData);

        // Check if the response indicates failure
        
            // Reset form and close modal if successful
            setFormData({
                name: '',
                profession: '',
                address: '',
                description: '',
            });
            setShowModal(false);
            setLoading(false);
        
    // } catch (err) {
    //     setError('An unexpected error occurred. Please try again.');
    // } finally {
    // }
};


  // Sync formData with editCoordinator when it changes
  useEffect(() => {
    if (editCoordinator) {
      setFormData({
        name: editCoordinator.name || '',
        profession: editCoordinator.profession || '',
        address: editCoordinator.address || '',
        description: editCoordinator.description || '',
      });
    }
  }, [editCoordinator]);
  return (
    <div>{showModal &&
      <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
              onClick={() => {setShowModal(false)
                setEditCoordinator(null)
              }}
              className="fixed inset-0 bg-black opacity-[66%]"
          />
          <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
              <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                  <div className='flex items-center justify-center w-full gap-3 mb-14'>
                      <h1 className='text-2xl font-semibold text-center'>Edit Coordinator</h1>
                  </div>

                  <div className='flex flex-col items-center gap-4 mt-5'>
                      <div className='relative w-full px-2 py-4 border border-black rounded-md'>
                          <input
                              type='text'
                              name='name'
                              value={formData.name}
                              onChange={handleInputChange}
                              placeholder='Name'
                              className='w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                          />
                          <p className='absolute px-2 bg-white -top-3 left-5'>Name</p>
                      </div>
                      <div className='relative w-full px-2 py-4 border border-black rounded-md'>
                          <input
                              type='text'
                              name='profession'
                              value={formData.profession}
                              onChange={handleInputChange}
                              placeholder='Profession'
                              className='w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                          />
                          <p className='absolute px-2 bg-white -top-3 left-5'>Profession</p>
                      </div>

                      <div className='relative w-full px-2 py-4 border border-black rounded-md'>
                          <input
                              type='text'
                              name='address'
                              value={formData.address}
                              onChange={handleInputChange}
                              placeholder='Address'
                              className='w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                          />
                          <p className='absolute px-2 bg-white -top-3 left-5'>Address</p>
                      </div>

                      <div className='relative w-full px-2 py-4 border border-black rounded-md'>
                          <input
                              type='text'
                              name='description'
                              value={formData.description}
                              onChange={handleInputChange}
                              placeholder='Description'
                              className='w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                          />
                          <p className='absolute px-2 bg-white -top-3 left-5'>Description</p>
                      </div>



                      <div className='flex flex-col items-center justify-center w-full gap-2 mt-4'>
                          {error && <p className='text-red-500'>{error}</p>}
                          <button
                              onClick={handleUpdateCoordinate}
                              className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                          >
                              {loading
                                  ? <div className="flex items-center justify-center w-full">
                                      <ThreeDots
                                          color="#ffffff"
                                          height={20}
                                          width={40}
                                          className="py-2"
                                      />
                                  </div>
                                  : 'Save'}
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>}
  </div>
  )
}

export default EditCoordinator