import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { useCategoriesContext } from '../../../contexts/CategoriesContextProvider';
import { useCoordinatesContext } from '../../../contexts/CoordinatesContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useLocationContext } from '../../../contexts/LocationContextProvider';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { baseUrl } from './../../../apis/base_url';
import { IoIosArrowBack } from 'react-icons/io';


function EditEvent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const { allCoordinates, getCoordinates } = useCoordinatesContext();
  const { allCategories, getCategories } = useCategoriesContext();
  const { getLocations, allLocations } = useLocationContext();
  const { token } = useAuthContext();
  const { updateEvent, getEventbyId, singleEventId, } = useEventContext();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedCardImage, setSelectedCardImage] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    category: null,
    coordinators: null,
    numberOfPersons: '',
    title: '',
    description: '',
    location: null,
    price: '',
    duration: '',
    startDate: '',
    endDate: '',
    bannerImage: null,
    cardImage: null,
  });


  const fetchAllEvents = async () => {
    await getEventbyId(token, id);
  }

  function formatDate(dateString) {
    const formattedDateString = dateString.replace(/\b(?:st|nd|rd|th)\b/g, '');
    const parsedDate = moment(formattedDateString, 'dddd,MMMM Do,YYYY').toDate();
    return moment(parsedDate).format('YYYY-MM-DD');
  }

  
  const setDataFromSingleEventId = async () => {
  
    if (singleEventId && singleEventId.event) {
      const bannerImagePath = singleEventId.event.banner_image
        ? `${baseUrl}/${singleEventId.event.banner_image.path}/${singleEventId.event.banner_image.name}`
        : null;
  
      const cardImagePath = singleEventId.event.card_image
        ? `${baseUrl}/${singleEventId.event.card_image.path}/${singleEventId.event.card_image.name}`
        : null;
  
      // const bannerBinary = bannerImagePath ? await convertImageToBinary(bannerImagePath) : null;
      // const cardBinary = cardImagePath ? await convertImageToBinary(cardImagePath) : null;
  
      setFormData({
        category: singleEventId.event.category_id || null,
        coordinators: singleEventId.event.coordinator_id || null,
        numberOfPersons: singleEventId.event?.total_group_members,
        title: singleEventId.event?.title,
        description: singleEventId.event?.description,
        price: singleEventId.event?.price,
        duration: singleEventId.event?.duration,
        startDate: formatDate(singleEventId.event?.date_at.split("-")[0]),
        endDate: formatDate(singleEventId.event?.date_at.split("-")[1]),
        bannerImage: bannerImagePath,
        cardImage: cardImagePath,
        location: singleEventId.event.location || null,
      });
    }
  };
  

  useEffect(() => {
    fetchAllEvents();
  }, [id]);


  useEffect(() => {
    setDataFromSingleEventId();
  }, [singleEventId]);

  const handleInputChange = (e) => {
    const { name, files, type } = e.target;
  
    if (type === "file" && files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
  
        if (name === "bannerImage") {
          setSelectedBanner({ file: files[0], base64: base64String });
        } else if (name === "cardImage") {
          setSelectedCardImage({ file: files[0], base64: base64String });
        }
      };
  
      reader.readAsDataURL(files[0]); // Convert file to base64 string
      setFormData({
        ...formData,
        [name]: files[0], // Save the file to formData
      });
    } else {
      setFormData({
        ...formData,
        [name]: e.target.value, // Handle other input types
      });
    }
  };

  const handleNextClick = async () => {
    
    setError(null);
    setLoading(true);
    const {
      category,
      coordinators,
      numberOfPersons,
      title,
      description,
      price,
      duration,
      startDate,
      endDate,
      bannerImage,
      cardImage,
      location,
    } = formData;
    
    console.log("Banner Image :", bannerImage)
    // Check if any required field is empty
    if (
      !category ||
      !coordinators ||
      !location ||
      !numberOfPersons ||
      !title ||
      !description ||
      !price ||
      !duration ||
      !startDate ||
      !endDate ||
      !bannerImage ||
      !cardImage
    ) {
      setError("Please fill all fields."); // Set error state to true to indicate incomplete fields
      setLoading(false);
      return; // Prevent moving to the next step
    }

    let data = new FormData();
    data.append('category_id', category);
    data.append('coordinator_id', coordinators);
    data.append('title', title);
    data.append('description', description);
    data.append('price', price);
    data.append('start_date', startDate);
    data.append('end_date', endDate);
    data.append('duration', duration);
    data.append('total_group_members', numberOfPersons);
    data.append('location', location);
    // Append banner_image only if it's a binary file
if (typeof bannerImage !== 'string') {
  data.append('banner_image', bannerImage); // Send binary file
}else{
  data.append('banner_image', '');
}

// Append card_image only if it's a binary file
if (typeof cardImage !== 'string') {
  data.append('card_image', cardImage); // Send binary file
} else {
  data.append('card_image', ""); // Send an empty value
}
    // data.append('banner_image', bannerImage);
    // data.append('card_image', cardImage);
    
    await updateEvent(id, data);
    setLoading(false);
    // isEventUpdate === false && navigate(-1)
  };

  const getAllOptions = async () => {
    if (allCategories == null) {
      await getCategories(token)
    }
    if (allCoordinates == null) {
      await getCoordinates(token)
    }
    if (allLocations == null) {
      await getLocations(token)
    }
  }


  useEffect(() => {
    getAllOptions();
  }, [])


  return (
    <section className='w-full p-4 rounded-md  '>
      <section className='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
        {/* {{-- title --}} */}
        <div className='flex gap-3 items-center mb-4'>
          <button
            onClick={() => navigate("/event/view/"+id)}
            className=" left-0 flex  items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack
              size={28}
              className="text-black cursor-pointer"
            />
          </button>
          <h1 className='font-semibold text-2xl'>Edit Event Details</h1>
        </div>

        {/* {{-- form  --}} */}
        <div className='flex flex-col space-y-6 mt-5'>
          {/* {{-- event category and no of persons --}} */}
          <div className='flex items-center w-full space-x-8'>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='category' value={formData.category}
                onChange={handleInputChange} className='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Category
                </option>
                {
                  allCategories && allCategories.map(category => (
                    <option value={category.id}>{category.name}</option>

                  ))
                }

              </select>
              <p className='absolute -top-3 left-5 bg-white px-2'>
                Select Category
              </p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='coordinators' value={formData.coordinators}
                onChange={handleInputChange} className='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Coordinators
                </option>
                {
                  allCoordinates && allCoordinates.map(coordinate => (
                    <option value={coordinate.id}>{coordinate.name}</option>

                  ))
                }
              </select>
              <p className='absolute -top-3 left-5 bg-white px-2'>
                Select Coordinators
              </p>
            </div>

          </div>
          <div className='flex items-center w-full space-x-8'>

            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='location' value={formData.location}
                onChange={handleInputChange} className='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Location
                </option>
                {
                  allLocations && allLocations.map(location => (
                    <option value={location.name}>{location.name}</option>

                  ))
                }
              </select>
              <p className='absolute -top-3 left-5 bg-white px-2'>
                Select Location
              </p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='numberOfPersons'
                value={formData.numberOfPersons}
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>No. of Persons</p>
            </div>
          </div>
          {/* {{-- title --}} */}
          <div className='relative border rounded-md border-black px-2 py-4 w-full'>
            <input
              type='text'
              name='title'
              value={formData.title}
              onChange={handleInputChange}
              className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
            />
            <p className='absolute -top-3 left-5 bg-white px-2'>Title</p>
          </div>
          {/* {{-- description --}} */}
          <div className='relative border rounded-md border-black px-2 py-4 w-full'>
            <input
              type='text'
              name='description'
              value={formData.description}
              onChange={handleInputChange}
              className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
            />
            <p className='absolute -top-3 left-5 bg-white px-2'>Description</p>
          </div>
          {/* {{-- price and duration --}} */}
          <div className='flex items-center w-full space-x-8'>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='price'
                value={formData.price}
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>Price</p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='duration'
                value={formData.duration}
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>Duration</p>
            </div>
          </div>
          {/* {{-- start date and end date --}} */}
          <div className='flex items-center w-full space-x-8'>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='date'
                name='startDate'
                value={formData.startDate}
                onChange={handleInputChange}
                className='w-full appearance-none p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>Start Date</p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='date'
                name='endDate'
                value={formData.endDate}
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>End Date</p>
            </div>
          </div>
          {/* {{-- upload banner image and upload card image --}} */}
          <div className='flex items-center w-full space-x-8'>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              {
                selectedBanner ?
                  <img
                    src={selectedBanner.base64}
                    alt='bannerImage'
                    className='w-32 h-32 object-cover mb-3'
                  /> :
                  formData.bannerImage && <img
                    src={formData.bannerImage}
                    alt='bannerImage'
                    className='w-32 h-32 object-cover mb-3'
                  />
              }
              <input
                type='file'
                name='bannerImage'
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>Banner Image</p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              {
                selectedCardImage ?
                  <img
                    src={selectedCardImage.base64}
                    alt='bannerImage'
                    className='w-32 h-32 object-cover mb-3'
                  /> :
                  formData.cardImage && <img
                    src={formData.cardImage}
                    alt='bannerImage'
                    className='w-32 h-32 object-cover mb-3'
                  />
              }
              <input
                type='file'
                name='cardImage'
                onChange={handleInputChange}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>Card Image</p>
            </div>
          </div>

          {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}
          {/* {{-- cancel and save button --}} */}
          <div className='flex items-center justify-end  w-full space-x-8'>
            {/* <button className='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
              Cancel
            </button> */}
            <button onClick={handleNextClick} className=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'>
              {loading
                ? <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
                : 'Save'}
            </button>
          </div>
        </div>
      </section>
    </section>
  )
}

export default EditEvent
