import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEventContext } from "../../../contexts/EventContextProvider";
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { IoIosArrowBack } from "react-icons/io";

function EditPlans({ onNext }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [eventPlan, setEventPlan] = useState(null);
  const [title, setTitle] = useState(null);
  const [day, setDay] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const { getEventbyId, singleEventId, editEventPlan, addEventPlans } = useEventContext();
  const { token } = useAuthContext();
  

  const option = {
    morning: "Morning",
    lunch_break: "Lunch Break",
    afternoon: "Afternoon",
    evening: "Evening",
    dinner: "Dinner",
    night_time: "Night Time",
  };


  function formatDate(inputDate) {
    if (!inputDate || typeof inputDate !== "string") {
      // Return an empty string or a default value if inputDate is invalid
      return "";
    }
    // Remove the day and "th" from the string
    const cleanedDate = inputDate.replace(
      /(\w+,)|(\d+)(st|nd|rd|th)/g,
      (match, day, num) => num || ""
    );
    // Parse the cleaned date string into a Date object
    const date = new Date(cleanedDate.trim());
    // Format the date to 'YYYY-MM-DD'
    return date.toISOString().split("T")[0];
  }

  useEffect(() => {
    if (singleEventId && singleEventId?.plan?.length > 0) {
      const firstPlan = singleEventId?.plan[0]; // Get the first plan object
      const formattedDate = formatDate(firstPlan?.day_date);

      if (formattedDate) {
        setDay(formattedDate);
      }

      // Extract non-empty keys and update eventPlan
      const updatedEventPlan = {};
      Object.keys(option).forEach((key) => {
        if (firstPlan[key]) {
          // Extract time and description from the field
          const timeAndDescription = firstPlan[key]
            .split(": ")
            .map((item) => item.trim());
          updatedEventPlan[key] = [
            {
              from_time: timeAndDescription[0]?.split(" - ")[0] || "", // Start time
              to_from: timeAndDescription[0]?.split(" - ")[1] || "", // End time
              description: timeAndDescription[1] || "", // Description
            },
          ];
        }
      });

      if (Object.keys(updatedEventPlan).length > 0) {
        const firstNonEmptyKey = Object.keys(updatedEventPlan)[0];
        setTitle(firstNonEmptyKey); // Pre-select the first non-empty option
        setEventPlan(updatedEventPlan); // Set eventPlan with the updated data
      }
    }
  }, [singleEventId]);

  const onTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);

    const plan = {
      [value]: [{ from_time: "", to_from: "", description: "" }],
    };

    if (!eventPlan) {
      setEventPlan({ ...plan });
    } else {
      setEventPlan({ ...eventPlan, ...plan });
    }

    console.log("Updated eventPlan:", eventPlan);
  };

  const onItemValueChange = (newValue, key, index, field) => {
    const updatedPlan = { ...eventPlan };
    updatedPlan[key][index][field] = newValue;
    setEventPlan(updatedPlan);
  };


  const onSaveClick = async () => {
    setLoading(true);
    if (
      !day ||
      Object.keys(eventPlan).length === 0 ||
      !Object.values(eventPlan).every((plan) => plan.length > 0)
    ) {
      setError("Please fill in all fields before saving.");
      setLoading(false);
      return;
    }

    const payload = {
      event_id: singleEventId?.plan[0]?.event_id || id,
      day_date: day,
      event_plan_id: singleEventId?.plan[0]?.id || null,
      ...eventPlan,
    };

    const response = singleEventId?.plan.length > 0 ? await editEventPlan(JSON.stringify(payload)) : await addEventPlans(token, JSON.stringify(payload))
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.");
    }
    navigate(-1)
    setEventPlan(null);
    setDay("");
    setTitle(null);
    setLoading(false);
  };

  useEffect(() => {
    if (id) getEventbyId(token, id);
  }, [id]);

  return (
    <section className="w-full p-4 rounded-md  ">
      <section className="w-full px-10 pt-5 pb-10 rounded-md  bg-white">
        {/* {{-- title --}} */}
        

        <div className='flex gap-3 items-center mb-4'>
          <button
            onClick={() => navigate("/event/view/"+id)}
            className=" left-0 flex  items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack
              size={28}
              className="text-black cursor-pointer"
            />
          </button>
          <h1 className='font-semibold text-2xl'>Edit Event Plans</h1>
        </div>

        {/* {{-- form  --}} */}
        <div className="flex flex-col space-y-6 mt-5">
          {/* {{-- start date and end date --}} */}

          {/* {{--  select date , title , start time, end time  --}} */}
          <div className="flex items-center w-full space-x-8">
            <div className="relative border rounded-md border-black px-2 py-4 w-1/2">
              <input
                type="date"
                value={day}
                onChange={(e) => {
                  setDay(e.target.value);
                  console.log("Check data format :", e.target.value);
                }}
                className="w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
              />
              <p className="absolute -top-3 left-5 bg-white px-2">Select Day</p>
            </div>
            <div className="relative border rounded-md border-black px-2 py-4 w-1/2">
              <select
                value={title}
                onChange={onTitleChange}
                className="border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full"
              >
                <option disabled selected={!title}>
                  Select Category
                </option>
                {Object.entries(option).map(([key, value]) => (
                  <option
                    key={key}
                    disabled={
                      eventPlan !== null
                        ? Object.keys(eventPlan).includes(key)
                          ? true
                          : false
                        : false
                    }
                    value={key}
                  >
                    {value}
                  </option>
                ))}
              </select>
              <p className="absolute -top-3 left-5 bg-white px-2">Title</p>
            </div>
          </div>
          {eventPlan &&
            Object.entries(eventPlan).map(([key, value]) => (
              <div
                key={key}
                className="flex flex-col gap-4 w-full p-3 border rounded-md shadow-md"
              >
                <h1 className="text-black font-bold text-base">
                  {option[key]}
                </h1>
                <div className="flex flex-col gap-4 justify-end">
                  {value.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-5 items-center justify-center"
                    >
                      {/* Start Time */}
                      <div className="relative border rounded-md border-black px-2 py-4 w-1/2">
                        <input
                          type="time"
                          name="from_time"
                          value={item["from_time"]}
                          onChange={(e) =>
                            onItemValueChange(
                              e.target.value,
                              key,
                              index,
                              "from_time"
                            )
                          }
                          className="w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                        />
                        <p className="absolute -top-3 left-5 bg-white px-2">
                          Start Time
                        </p>
                      </div>

                      {/* End Time */}
                      <div className="relative border rounded-md border-black px-2 py-4 w-1/2">
                        <input
                          type="time"
                          name="to_from"
                          value={item["to_from"]}
                          onChange={(e) =>
                            onItemValueChange(
                              e.target.value,
                              key,
                              index,
                              "to_from"
                            )
                          }
                          className="w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                        />
                        <p className="absolute -top-3 left-5 bg-white px-2">
                          End Time
                        </p>
                      </div>

                      {/* Description */}
                      <div className="relative border rounded-md border-black px-2 py-4 w-full">
                        <input
                          type="text"
                          name="description"
                          value={item["description"]}
                          onChange={(e) =>
                            onItemValueChange(
                              e.target.value,
                              key,
                              index,
                              "description"
                            )
                          }
                          className="w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                        />
                        <p className="absolute -top-3 left-5 bg-white px-2">
                          Description
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            

          {/* {
            eventPlan && Object.entries(eventPlan).map(([key, value]) => {
              return <div className="flex flex-col gap-4 w-full   p-3 border rounded-md shadow-md">
                <h1 className="text-black font-bold text-base">{option[key]}</h1>
                <div className="flex flex-col gap-4 justify-end">
                  {value.map((item, index) => {
                    console.log(index)
                    return <div key={index} className="flex gap-5 items-center justify-center">
                     
                      <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
                        <input
                          type='time'
                          name="from_time"
                          value={item["from_time"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "from_time")}
                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>Start Time</p>
                      </div>
                     
                      <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
                        <input
                          type='time'
                          name="to_from"
                          value={item["to_from"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "to_from")}

                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>End Time</p>
                      </div>
                     
                      <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                        <input
                          type='text'
                          name="description"
                          value={item["description"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "description")}
                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>Description</p>
                      </div>
                      {index !== 0 && ( // Check if it's not the first row
                        <div className='relative  px-2 py-10 flex items-center justify-center '>
                          <RiDeleteBin6Line
                            onClick={() => onItemValueDelete(key, index)}
                            size={20}
                            className='absolute right-2 top-2 cursor-pointer text-red-500'
                          />
                        </div>
                      )}
                    </div>
                  })}
                  <div className='flex items-center justify-end  w-full space-x-8'>
                    <button onClick={() => addTimeAndDescription(key)} className='border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B] text-md font-normal'>
                      Add
                    </button>
                  </div>
                </div>

              </div>
            })
          } */}
          {error && (
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>
          )}
          {/* save button */}
          <div className="flex items-end justify-end  w-full space-x-8">
            <button
              onClick={onSaveClick}
              className=" px-16 py-2 rounded-md w-max  text-white bg-[#8DC67B] text-md font-semibold"
            >
              {loading ? (
                <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
              ) : (
                "Edit plan"
              )}
            </button>
          </div>

          {/* {{-- cancel and next button --}} */}
          {/* <div className="flex items-center justify-center  w-full space-x-8">
            <Link to={"/all-events"}>
              <button className=" px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold">
                Submit
              </button>
            </Link>
          </div> */}
        </div>

        {/* </div> */}
      </section>
    </section>
  );
}

export default EditPlans;
