// CoordinatesContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const CoordinatesContext = createContext();

export const CoordinatesContextProvider = ({ children }) => {
  const [allCoordinates, setAllCoordinates] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(20);  
  const [isLoadingCoordinates,setIsLoadingCoordinates] = useState(false);

  const getCoordinates = async (token, page = currentPage, itemsPerPage = limit, searchQuery = '') => {
    setIsLoadingCoordinates(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", `/api/v1/get/coordinators?page=${page}&per_page=${itemsPerPage}&search=${searchQuery}`, {}, headers);
      if (response && response["success"] === true) {
        setAllCoordinates(response["data"].data);
        setPageCount(response?.data?.last_page);
        setLimit(response?.data?.per_page);
        setIsLoadingCoordinates(false);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsLoadingCoordinates(false);
    }
  };

  const addCoordinate = async (token, coordinateData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/set/coordinator", coordinateData, headers);
      if (response && response["success"] === true) {
        setAllCoordinates([...allCoordinates, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteCoordinate = async (token, coordinateId) => {
    try {
      // Implement the logic to delete a coordinate using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateCoordinate = async (coordinateId, coordinateData) => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", `/api/v1/singleCoordinator/${coordinateId}`, coordinateData, headers);
      if (response && response["success"] === true) {
        getCoordinates();
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  return (
    <CoordinatesContext.Provider
      value={{
        allCoordinates,
        getCoordinates,
        addCoordinate,
        deleteCoordinate,
        updateCoordinate,
        isLoadingCoordinates,
        currentPage,
        setCurrentPage,
        pageCount,
      }}
    >
      {children}
    </CoordinatesContext.Provider>
  );
};

export const useCoordinatesContext = () => {
  const coordinatesContextValue = useContext(CoordinatesContext);
  return coordinatesContextValue;
};
