import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useLocationContext } from '../../contexts/LocationContextProvider';
import AddLocation from './add_icon';

import ConfirmationDeletePopUp from '../../components/confirmation_delete_popup';
import EditIcon from './edit_icon';
import { useIconsContext } from '../../contexts/IconsContextProvider';
import { baseUrl } from '../../apis/base_url';
import ReactPaginate from 'react-paginate';

function AllIcons() {
  const { token } = useAuthContext();
  const { getIcons, allIcons,setCurrentPage,
    currentPage,
    pageCount, } = useIconsContext();
  const [searchInput, setSearchInput] = useState('');
  const [showAddIconPopup, setShowAddIconPopup] = useState(false);

  const getAllIcons = async () => {
    await getIcons(token);
  }

  const handlePageChange = (page = { selected: 0 }) => {
    const selectedPage = page.selected + 1;
    setCurrentPage(selectedPage);
    getIcons(selectedPage, 10); 
  };

  useEffect(() => {
    getAllIcons();
  }, []);

  return (
    <div className='px-5 py-10'>
      {allIcons ? (
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">Icons</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              {/* <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form> */}
            </div>

            {/* Actions and Filter Buttons */}
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

              <button
                type="button"
                onClick={() => setShowAddIconPopup(true)}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300"
              >
                <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add Icon
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Name
                  </th>

                  <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {
                  allIcons && allIcons.map((data, index) => {
                    return <TableRow  key={index} id={index} data={data} />
                  })
                }

              </tbody>
            </table>
          </div>
           {/* Pagination */}
      <div className="flex justify-center bg-[#8DC67B]">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              forcePage={currentPage - 1}
            />
          </div>
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}

      {/* add data */}
      {/* AddLocation component */}
      {/* Replace this comment with your AddLocation component */}
      <AddLocation showModel={showAddIconPopup} setShowModel={setShowAddIconPopup} />
    </div>
  );
}

export default AllIcons;



function TableRow({ id, data }) {
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { deleteIcon } = useIconsContext();
  const { token } = useAuthContext();

  const handleDeleteButtonClick = async (iconId) => {
    // Additional actions or state changes can be performed here
    const response = await deleteIcon(token, iconId);
    if (response) {
      setShowDeletePopup(false);
    }

  };

  return <tr key={id} className="border-b">
    <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap">
      {data.id}
    </td>
    <td className="px-4 py-3 flex justify-center">
      <img src={baseUrl + "/" + data.path} alt="Icon" className="h-9 w-9 object-cover object-center" />

    </td>

    <td className="px-2 py-3">
      <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700">

        <li className="cursor-pointer" onClick={() => setShowUpdatePopup(true)}>
          <img src={'images/sidebar/Edit.svg'} alt="Edit" />
        </li>
        <li className="cursor-pointer" onClick={() => setShowDeletePopup(true)}>
          <img src={'images/sidebar/Delete.svg'} alt="Delete" />
        </li>

        {/* add data */}
        <EditIcon id={data.id} path={data.path} showModel={showUpdatePopup} setShowModel={setShowUpdatePopup} />
        {/* delete popup */}
        <ConfirmationDeletePopUp handleCancel={() => setShowDeletePopup(false)} handleDelete={() => handleDeleteButtonClick(data.id)} title={"Are you sure you want to delete this data."} showModel={showDeletePopup} setShowModel={setShowDeletePopup} />
      </ul>
    </td>
  </tr>
}