import React, { useEffect, useState } from "react";
import { useFinanceContext } from "../../contexts/FinanceContextProvider";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../apis/base_url";
import { ThreeDots } from "react-loader-spinner";

const ShowSingleUser = () => {
  const [checkStatus, setCheckStatus] = useState("");

  const {
    getSingleFinance,
    singleFinanceData,
    updateViewFinance,
    updateStatus,
  } = useFinanceContext();

  const { id } = useParams();

  useEffect(() => {
    getSingleFinance(id);
  }, []);

  return (
    <div className="px-5 py-5">
      {singleFinanceData === null ? (
        <h1>Loading...</h1>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 relative w-[100%] overflow-auto">
          {/* userImage */}
          <div className="userImage mb-2">
            <img
              className="rounded-lg w-52"
              src={
                singleFinanceData.profile_picture
                  ? `${baseUrl}${singleFinanceData.profile_picture}`
                  : ""
              }
              alt="not found"
            />
          </div>
          {/* Username */}
          <div className="username mb-2">
            <h2 className="text-lg font-semibold ">Full Name</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.full_name}
            </p>
          </div>

          {/* Email */}
          <div className="useremail mb-2 ">
            <h2 className="text-lg font-semibold">Email</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.email}
            </p>
          </div>

          {/* Gender */}
          <div className="userGender mb-2 ">
            <h2 className="text-lg font-semibold">Gender</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.gender}
            </p>
          </div>

          {/* Country */}
          <div className="userCountry mb-2 ">
            <h2 className="text-lg font-semibold">Country</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.country}
            </p>
          </div>

          {/* DOB */}
          <div className="userdob mb-2 ">
            <h2 className="text-lg font-semibold">DOB</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.dob}
            </p>
          </div>

          {/* Age */}
          <div className="userAge mb-2 ">
            <h2 className="text-lg font-semibold">Age</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.age}
            </p>
          </div>

          {/* Phone */}
          <div className="userPhone mb-2 ">
            <h2 className="text-lg font-semibold">Phone number</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.phone}
            </p>
          </div>

          {/* Employment Status */}
          <div className="useremployment mb-2 ">
            <h2 className="text-lg font-semibold">Employment Status</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.emp_status}
            </p>
          </div>

          {/* profession */}
          <div className="userprofession mb-2 ">
            <h2 className="text-lg font-semibold">profession</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData.profession}
            </p>
          </div>

          {/* Annual gross income */}
          <div className="userannualgrossincome mb-2 ">
            <h2 className="text-lg font-semibold">Annual gross income</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {singleFinanceData?.a_gross_income}
            </p>
          </div>

          {/* Academic Degrees */}
          <div className="useracademic mb-2 ">
            <h2 className="text-lg font-semibold">Academic Degrees</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              {JSON.parse(singleFinanceData?.degree).map((val) => (
                <p>{val}</p>
              ))}
              {/* {singleFinanceData?.degree} */}
            </p>
            {/* <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">2: Master’s</p>
        <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">3: PhD</p> */}
          </div>

          {/* Social Media */}
          <div className="usersocialmedia mb-2 ">
            <h2 className="text-lg font-semibold">Social Media</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              <a href={singleFinanceData?.social_media_link} target="_blank">
                {singleFinanceData?.social_media_link}
              </a>
            </p>
          </div>

          {/* Package url */}
          <div className="userpackageurl mb-2 ">
            <h2 className="text-lg font-semibold">Package</h2>
            <p className="shadow-sm p-2 rounded-xl font-medium  bg-[#8DC67B] text-white">
              <a href={singleFinanceData?.pkg_link} target="_blank">
                {singleFinanceData?.pkg_link}
              </a>
            </p>
          </div>

          {singleFinanceData?.status === "" ||
          singleFinanceData?.status === "Pending" ? (
            <div className="accept-reject flex gap-5">
              {/* Reject button */}
              <button
                type="button"
                className="px-5 py-2 bg-red-400 text-white"
                // disabled={!updateStatus}
                onClick={async () => {
                  setCheckStatus("Rejected");
                  await updateViewFinance(singleFinanceData?.unique_string, {
                    status: "Rejected",
                  });
                  // setIsModalOpen(false);
                  // setsingleFinanceData(null);
                  // setCheckStatus(null)
                }}
              >
                {!updateStatus && checkStatus === "Rejected" ? (
                  <div className="flex items-center justify-center w-full">
                    <ThreeDots
                      color="#ffffff"
                      height={20}
                      width={40}
                      className="py-2"
                    />
                  </div>
                ) : (
                  "Rejected"
                )}
              </button>
              {/* Accept button */}
              <button
                type="button"
                className="px-5 py-2 bg-[#8DC67B]"
                // disabled={!updateStatus}
                onClick={async () => {
                  setCheckStatus("Accepted");
                  await updateViewFinance(singleFinanceData?.unique_string, {
                    status: "Accepted",
                  });
                  // setIsModalOpen(false);
                  // setSelectedUserId(null);
                  // setCheckStatus(null)
                }}
              >
                {!updateStatus && checkStatus === "Accepted" ? (
                  <div className="flex items-center justify-center w-full">
                    <ThreeDots
                      color="#ffffff"
                      height={20}
                      width={40}
                      className="py-2"
                    />
                  </div>
                ) : (
                  "Accepted"
                )}
              </button>
            </div>
          ) : singleFinanceData?.status === "Accepted" ? (
            <h1 className="bg-[#8DC67B] p-3 text-center mt-5 font-bold text-white text-lg rounded-lg">
              Request accepted
            </h1>
          ) : singleFinanceData?.status === "Rejected" ? (
            <h1 className="bg-[#8DC67B] p-3 text-center mt-5 font-bold text-white text-lg rounded-lg">
              Request rejected
            </h1>
          ) : (
            ""
          )}
          {/* <div className='accept-reject flex gap-5'>
        <button type='button' className='px-5 py-2 bg-red-400 text-white'
        onClick={()=>updateViewFinance(singleFinanceData?.unique_string, {status:'Rejected'})}
        >
          Rejected
        </button>
        <button type='button' className='px-5 py-2 bg-[#8DC67B]'
        onClick={()=>updateViewFinance(singleFinanceData?.unique_string, {status:'Accepted'})}
        >
          Accepted
        </button>
      </div> */}
        </div>
      )}
    </div>
  );
};

export default ShowSingleUser;
