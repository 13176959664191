// ContactSupportContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";

const ContactSupportContext = createContext();

export const ContactSupportContextProvider = ({ children }) => {
  const [allQueries, setAllQueries] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(10); 

  const getAllQueries = async (page = currentPage, itemsPerPage = limit, searchQuery = '') => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const headers = jsonHeader(token)

      const response = await ApiTemplate("get", `/api/v1/get/customer/support?page=${page}&limit=${itemsPerPage}&search=${searchQuery}`, {}, headers);
      if (response && response["success"] === true) {
        setAllQueries(response["data"].data);
        setPageCount(response?.data?.last_page)
        setLimit(response?.data?.per_page)
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addAllQueries = async (token, blogData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/store/post", blogData, headers);
      if (response && response["success"] === true) {

        await getAllQueries(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };


  return (
    <ContactSupportContext.Provider
      value={{
        allQueries,
        getAllQueries,
        addAllQueries,
        currentPage,
        setCurrentPage,
        pageCount,
      }}
    >
      {children}
    </ContactSupportContext.Provider>
  );
};

export const useContactSupportContext = () => {
  const blogsContextValue = useContext(ContactSupportContext);
  return blogsContextValue;
};
