import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useCoordinatesContext } from '../../contexts/CoordinatesContextProvider';
import AddCoordinator from './add_coordinator';
import EditCoordinator from './edit_coordinator';
import ReactPaginate from 'react-paginate';
import { ColorRing } from 'react-loader-spinner';

function AllCoordinators() {
  // Define state variables and functions to handle dropdown toggles, search, etc.
  const [searchInput, setSearchInput] = useState('');
  const [showAddCoordinatorPopup, setShowAddCoordinatorPopup] = useState(false);
  const [editCoordinator, setEditCoordinator] = useState(null);
  const [openEditCoordinator, setOpenEditCoordinator] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [coordinatorToDelete, setCoordinatorToDelete] = useState(null);



  const { token } = useAuthContext();
  const { allCoordinates,
    getCoordinates, isLoadingCoordinates, setCurrentPage, currentPage, pageCount } = useCoordinatesContext();

  const getAllCoordinates = async () => {
    await getCoordinates(token);
  }

  useEffect(() => {
    getAllCoordinates();
  }, []);


  const handleDeleteCoordinate = async () => {
    if (!coordinatorToDelete) return;
    const response = deleteCoordinate(coordinatorToDelete.id, token); // Replace with your API call
    if (response.success) {
      setShowDeletePopup(false);
      setCoordinatorToDelete(null);
      await getAllCoordinates(); // Refresh the coordinators list
    } else {
      alert("Failed to delete the coordinator.");
    }
  };

  const deleteCoordinate = () =>{
    
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getCoordinates(token, currentPage, 10, searchInput);
    }, 500);
  
    return () => clearTimeout(delayDebounce);
  }, [searchInput]);

  const handlePageChange = (page = { selected: 0 }) => {
    const selectedPage = page.selected + 1;
    setCurrentPage(selectedPage);
    getCoordinates(token, selectedPage, 10, searchInput); 
  };



  return (
    <div className='px-5 py-10'>
      {allCoordinates ? (
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="px-4 pt-4 text-3xl font-semibold">COORDINATORS</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              <form className="flex items-center"
              onSubmit={(e) => {
                e.preventDefault();
              }}>
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form>
            </div>

            {/* Actions and Filter Buttons */}
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

              <button
                type="button"
                onClick={() => setShowAddCoordinatorPopup(true)}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300"
              >
                <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add Coordinator
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            {
              isLoadingCoordinates ? <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["black"]}
            /></div>:
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Profession
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Address
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Description
                  </th>

                  <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {allCoordinates.map((coordinator, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap">
                      {coordinator.id}
                    </td>
                    <td className="px-4 py-3">{coordinator.name}</td>
                    <td className="px-4 py-3">{coordinator.profession}</td>
                    <td className="px-4 py-3">{coordinator.address}</td>
                    <td className="px-4 py-3">{coordinator.description}</td>

                    <td className="px-2 py-3">
                      <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700">
                        <li className='cursor-pointer' onClick={()=>{   
                          setEditCoordinator(coordinator);
                          setOpenEditCoordinator(true)
                        }}>
                            <img src={'images/sidebar/Edit.svg'} alt="Edit" />
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            }
          </div>
          {/* Pagination */}
          <div className="flex justify-center bg-[#8DC67B]">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              forcePage={currentPage - 1}
            />
          </div>
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}


      {/* Replace this comment with your AddLocation component */}
      <AddCoordinator showModel={showAddCoordinatorPopup} setShowModel={setShowAddCoordinatorPopup} />
      <EditCoordinator showModal={openEditCoordinator} setShowModal={setOpenEditCoordinator} editCoordinator={editCoordinator} setEditCoordinator={setEditCoordinator}/>
   
      {showDeletePopup && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div
      onClick={() => setShowDeletePopup(false)}
      className="fixed inset-0 bg-black opacity-50"
    />
    <div className="relative z-50 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-lg font-semibold">
        Are you sure you want to delete this coordinator?
      </h2>
      <div className="flex justify-end gap-4">
        <button
          onClick={() => setShowDeletePopup(false)}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={handleDeleteCoordinate}
          className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default AllCoordinators