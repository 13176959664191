import { createContext, useContext, useEffect, useState } from "react";
import ApiTemplate from "../apis/api_template";

const ReportContext = createContext();

export const ReportsContextProvider = ({children}) =>{
  const [financialReports, setFinancialReports] = useState(null);
  const [reportsGetting, setReportGetting] = useState(false);


  const ReportsFromTo = async (payload={from:'',to:''}) => {
    const {from,to}=payload
    const token = JSON.parse(localStorage.getItem('token'));
    setReportGetting(true);
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", `/api/v1/bookingReport?from=${from}&to=${to}`, {} , headers);
      if (response && response["success"] === true) {
        setFinancialReports(response["data"]);
        setReportGetting(false);
        return true;
      } else {
        console.log(response["message"], response.message);
        setReportGetting(false);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      setReportGetting(false);
      return false;
    }
  };

  useEffect(()=>{
    if(window.location.href.includes('ledger-reports')){
      ReportsFromTo();
    }
  },[])
  return(
    <ReportContext.Provider value={{
      ReportsFromTo,
      financialReports,
      reportsGetting
    }}>
      {children}
    </ReportContext.Provider>
  )
};

export const useReportsContext = () => {
  const ReportContextValue = useContext(ReportContext);
  return ReportContextValue;
};
