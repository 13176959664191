import React, { useEffect, useState } from "react";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { useSubscriptionContext } from "../../contexts/SubscriptionContextProvider";

const EditPlan = ({ showModel, setShowModel, individualId }) => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    coins: "",
    discount_percentage: "",
    billing_cycle: "",
    subscription_detail: [""],
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { updatePlan } = useSubscriptionContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error when the user starts filling fields
    setError("");
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...formData.subscription_detail];
    updatedFeatures[index] = value;
    setFormData({
      ...formData,
      subscription_detail: updatedFeatures,
    });
  };

  const addFeatureField = () => {
    setFormData({
      ...formData,
      subscription_detail: [...formData.subscription_detail, ""], // Add an empty string for a new feature field
    });
  };

  const removeFeatureField = (index) => {
    if (formData.subscription_detail.length > 1) {
      const updatedFeatures = formData.subscription_detail.filter(
        (_, i) => i !== index
      );
      setFormData({
        ...formData,
        subscription_detail: updatedFeatures,
      });
    } else {
      setError("At least one feature is required.");
    }
  };
  

  const editPlan = async () => {
    setIsLoading(true);
    // Validate that all fields are filled
    if (
      !formData.name ||
      !formData.price ||
      !formData.coins ||
      !formData.discount_percentage ||
      !formData.billing_cycle ||
      formData.subscription_detail.some((feature) => !feature) // Ensure no empty features
    ) {
      setError("Fill all fields before adding a new plan.");
      setIsLoading(false);
      return;
    }

    try {
      await updatePlan(individualId.id, formData);
      setIsLoading(false);
      setShowModel(false);
      setFormData({
        name: "",
        price: "",
        coins: "",
        discount_percentage: "",
        billing_cycle: "",
        subscription_detail: [""],
      });
    } catch (err) {
      console.error("Error adding plan:", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showModel && individualId) {
      setFormData({
        name: individualId?.name,
        coins: individualId?.coins,
        price: individualId?.price,
        discount_percentage: individualId?.discount_percentage,
        billing_cycle: individualId?.billing_cycle,
        subscription_detail:
          individualId?.subscription_detail?.map((detail) => detail?.title) ||
          [],
      });
    }
  }, [individualId, showModel]);

  return (
    <>
      {showModel && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            onClick={() => setShowModel(false)}
            className="fixed inset-0 bg-black opacity-[15%]"
          />
          <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
            <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
              <div className="w-full gap-3 mb-14">
                <h1 className="text-2xl font-semibold text-center">
                  Edit Plan
                </h1>
                {error && (
                  <p className="text-red-500 text-center text-sm font-semibold">
                    {error}
                  </p>
                )}
              </div>

              <div className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-gray-200">
                <div className="flex flex-col items-center gap-4 mt-5 ">
                  {[
                    { name: "name", label: "Plan Name" },
                    { name: "price", label: "Price" },
                    { name: "coins", label: "Coins" },
                    {
                      name: "discount_percentage",
                      label: "Discount Percentage",
                    },
                  ].map((field) => (
                    <div
                      key={field.name}
                      className="relative w-full px-2 py-4 border border-black rounded-md"
                    >
                      <input
                        type="text"
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleInputChange}
                        placeholder={field.label}
                        className="w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                      />
                      <p className="absolute px-2 bg-white -top-3 left-5">
                        {field.label}
                      </p>
                    </div>
                  ))}

                  {/* Dropdown for Billing Cycle */}
                  <div className="relative w-full px-2 py-4 border border-black rounded-md">
                    <select
                      name="billing_cycle"
                      value={formData.billing_cycle}
                      onChange={handleInputChange}
                      className="w-full p-0 text-base font-normal border-0 focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                    >
                      <option value="" disabled>
                        Select Billing Cycle
                      </option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                    <p className="absolute px-2 bg-white -top-3 left-5">
                      Billing Cycle
                    </p>
                  </div>

                  {/* Feature Fields */}
                  <div className="w-full">
                    <p className="text-lg font-semibold mb-2">
                      Subscription Details
                    </p>
                    {formData.subscription_detail.map((feature, index) => (
                      <div
                        key={index}
                        className="relative flex items-center gap-3 mb-2"
                      >
                        <input
                          type="text"
                          value={feature}
                          onChange={(e) =>
                            handleFeatureChange(index, e.target.value)
                          }
                          placeholder={`Feature ${index + 1}`}
                          className="flex-1 px-2 py-2 border border-black rounded-md"
                        />
                        {/* Add Feature Button */}
                        <button
                          onClick={addFeatureField}
                          className="absolute right-8 text-black"
                          title="Add Feature"
                        >
                          <IoMdAddCircle size={25} />
                        </button>
                        {/* Remove Feature Button */}
                        {index > 0 && (
                          <button
                            onClick={() => removeFeatureField(index)}
                            className="absolute right-2 text-red-500"
                            title="Remove Feature"
                          >
                            <IoMdRemoveCircle size={25} />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-2 mt-4">
                <button
                  onClick={editPlan}
                  className="w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold"
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPlan;
