import React, { useEffect, useState } from "react";
import { useSubscriptionContext } from "../../contexts/SubscriptionContextProvider";
import { ColorRing } from "react-loader-spinner";
import ConfirmationDeletePopUp from "../../components/confirmation_delete_popup";
import AddPlan from "./addPlan";
import ClickAwayListener from "react-click-away-listener";
import { format } from "date-fns";
import EditPlan from "./editPlan";

const Subscription = () => {
  const {
    plansList,
    isPlansGet,
    deletePlan,
    addPlan,
    isSinglePlan,
    getIndividualPlan,
    individualPlanData,
    getAllPlans
  } = useSubscriptionContext();
  const [showAddPlanPopup, setShowAddPlanPopup] = useState(false);
  const [showDeletePlanPopup, setShowDeletePlanPopup] = useState(false);
  const [getSinglePlanPopup, setGetSinglePlanPopup] = useState(false);
  const [showEditPlanPopup, setShowEditPlanPopup] = useState(false);
  const [individualId, setIndividualId]= useState(null);


  const handleDeleteButtonClick = async (id) => {
    await deletePlan(id);
    setShowDeletePlanPopup(false);
  };

  const handleAddPlan = async (payload) => {
    await addPlan(payload);
    setShowAddPlanPopup(false);
  };

  const handleEditPlan  = () =>{
    // await EditPlan(payload);
  }

  const getSinglePlan = async (id) => {
    setGetSinglePlanPopup(true);
    await getIndividualPlan(id);
  };

  useEffect(()=>{
    getAllPlans()
  },[])


  return (
    <div className="px-5 py-10">
      <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
        <h1 className="px-4 pt-4 text-3xl font-semibold">PLANS</h1>

        {/* Add New Plan */}
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <div></div>
          <button
            type="button"
            onClick={() => setShowAddPlanPopup(true)}
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300"
          >
            <svg
              className="h-3.5 w-3.5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              />
            </svg>
            Add New Plan
          </button>
        </div>

        <div className="overflow-x-auto">
          {isPlansGet ? (
            <div className="flex items-center justify-center w-full my-20 text-center">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["black"]}
              />
            </div>
          ) : (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Billing Cycle
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Coins
                  </th>

                  <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {plansList &&
                  plansList.map((plan, index) => (
                    <tr key={index} className="border-b">
                      <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap">
                        {plan.name}
                      </td>
                      <td className="px-4 py-3">{plan.price}</td>
                      <td className="px-4 py-3">{plan.billing_cycle}</td>
                      <td className="px-4 py-3">{plan.coins}</td>
                      <td className="px-4 py-3">
                        <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700">
                        <li
                            className="cursor-pointer"
                            onClick={() => {setShowEditPlanPopup(true);
                              setIndividualId(plan)
                            }}
                          >
                            <img
                              src={"images/sidebar/Edit.svg"}
                              alt="Edit"
                            />
                          </li>
                          <li
                            className="cursor-pointer"
                            onClick={() => getSinglePlan(plan.id)}
                          >
                            <img src={"images/sidebar/Show.svg"} alt="Edit" />
                          </li>
                          <li
                            className="cursor-pointer"
                            onClick={() => setShowDeletePlanPopup(true)}
                          >
                            <img
                              src={"images/sidebar/Delete.svg"}
                              alt="Delete"
                            />
                          </li>
                         
                          {/* add Plan Modal */}
                          <AddPlan
                            handleAddPlan={handleAddPlan}
                            showModel={showAddPlanPopup}
                            setShowModel={setShowAddPlanPopup}
                          />
                          {/* Delete Plan Modal */}
                          <ConfirmationDeletePopUp
                            handleCancel={() => setShowDeletePlanPopup(false)}
                            handleDelete={() =>
                              handleDeleteButtonClick(plan.id)
                            }
                            title={
                              "Are you sure you want to delete this category."
                            }
                            showModel={showDeletePlanPopup}
                            setShowModel={setShowDeletePlanPopup}
                          />
                          <EditPlan 
                            showModel={showEditPlanPopup}
                            setShowModel={setShowEditPlanPopup}
                            handleEditPlan={handleEditPlan}
                            individualId={individualId}
                          />
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {getSinglePlanPopup && (
        <div className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 shadow-lg">
          <ClickAwayListener onClickAway={() => setGetSinglePlanPopup(false)}>
            <div className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto">
              <button
                onClick={() => setGetSinglePlanPopup(false)}
                className="absolute text-4xl font-semibold top-2 right-2 text-gray-500 hover:text-black dark:text-gray-400"
              >
                &times;
              </button>
              {isSinglePlan ? (
                <div className="flex items-center justify-center w-full my-20 text-center">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["black"]}
                  />
                </div>
              ) : (
                <div>
                
                  {/* Plan Name */}
                  <div className="plan-name mb-2">
                    <h2 className="text-lg font-semibold">Plan Name</h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.name}
                    </p>
                  </div>

                  {/* Price */}
                  <div className="plan-price mb-2">
                    <h2 className="text-lg font-semibold">Price</h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.price}
                    </p>
                  </div>

                  {/* Billing Cycle */}
                  <div className="billing-cycle mb-2">
                    <h2 className="text-lg font-semibold">Billing Cycle</h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.billing_cycle}
                    </p>
                  </div>

                  {/* Address */}
                  <div className="plan-coins mb-2">
                    <h2 className="text-lg font-semibold">Coins</h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.coins}
                    </p>
                  </div>

                  {/* Created at */}
                  <div className="plan-created_at mb-2">
                    <h2 className="text-lg font-semibold">Created at</h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {format(individualPlanData?.created_at, "yyyy/MM/dd")}
                    </p>
                  </div>

                  {/* Discount Percentage */}
                  <div className="plan-discount_percentage mb-2">
                    <h2 className="text-lg font-semibold">
                      Discount Percentage
                    </h2>
                    <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.discount_percentage}
                    </p>
                  </div>

                  {/* Subscription Details */}
                  <div className="subscription_detail mb-2">
                    <h2 className="text-lg font-semibold">
                      Subscription Details
                    </h2>
                    <ol type="1" style={{ listStyleType: "decimal" }} className="shadow-sm p-2 ps-4 rounded-xl font-medium text-slate-700">
                      {individualPlanData?.subscription_detail?.map((details,index)=>(
                      <li key={index}>{details?.title}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

export default Subscription;
