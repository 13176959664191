import React, { useState , useEffect} from 'react'
import { useUsersContext } from '../../contexts/UsersContextProvider';
import {ColorRing} from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import ClickAwayListener from 'react-click-away-listener';
import { RxAvatar } from "react-icons/rx";


function AllUsers() {
    // Define state variables and functions to handle dropdown toggles, search, etc.
    const [showActionsDropdown, setShowActionsDropdown] = useState(false);
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchInput, setSearchInput] = useState('');
    const {
      allUsers,
      getUsers,
      isLoadingUsers,
      setCurrentPage,
      currentPage,
      pageCount,
      getSingleUser,
      isSingleUser,
      singleUserData,
      emptySingleUser
    } = useUsersContext();

    // Function to toggle the Actions dropdown
    // const toggleActionsDropdown = () => {
    //   setShowActionsDropdown(!showActionsDropdown);
    // };

    // // Function to toggle the Filter dropdown
    // const toggleFilterDropdown = () => {
    //   setShowFilterDropdown(!showFilterDropdown);
    // };

    const handlePageChange = (page = { selected: 0 }) => {
      const selectedPage = page.selected + 1;
      setCurrentPage(selectedPage);
      getUsers(selectedPage, 10, searchInput); 
    };

    const openModal =async (id) => {
      setIsModalOpen(true);
      await getSingleUser(id);
      // handlePageChange({ selected: 0 });
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      emptySingleUser(null)
    };

    const toggleModal = () =>{
      setIsModalOpen(false)
    }
    useEffect(() => {
      const delayDebounce = setTimeout(() => {
        getUsers(currentPage, 10, searchInput);
      }, 500);
    
      return () => clearTimeout(delayDebounce);
    }, [searchInput]);


  return (
    <div className='px-5 py-10'>
      <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <h1 className="font-semibold text-3xl px-4 pt-4">USERS</h1>
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
        
        <div className="w-full md:w-1/2">
          {/* search */}
         
          <form className="flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search"
                required
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </form>
        </div>

           {/* Actions and Filter Buttons */}
        {/* <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3"> */}
          {/* <button
            type="button"
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              />
            </svg>
            Add User
          </button> */}

          {/* <button
            onClick={toggleActionsDropdown}
            data-dropdown-toggle="actionsDropdown"
            className={`relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover-bg-gray-700 ${showActionsDropdown ? 'bg-primary-100 text-primary-700' : ''}`}
            type="button"
          >
            
            Actions
            <svg className="w-5 h-5 -rotate-90 ms-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
            <div
              id="actionsDropdown"
              className={`absolute right-0 z-10 ${showActionsDropdown ? 'block' : 'hidden'} bg-white divide-y divide-gray-100 rounded shadow top-10 w-44 dark:bg-gray-700 dark:divide-gray-600`}
            >
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                <li>
                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
                    Mass Edit
                  </a>
                </li>
              </ul>
              <div className="py-1">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover-bg-gray-100 dark-hover-bg-gray-600 dark-text-gray-200 dark-hover-text-white">
                  Delete all
                </a>
              </div>
            </div>
          </button> */}

          {/* <button
            onClick={toggleFilterDropdown}
            data-dropdown-toggle="filterDropdown"
            className={`relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover-bg-gray-100 hover-text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark-bg-gray-800 dark-text-gray-400 dark-border-gray-600 dark-hover-text-white dark-hover-bg-gray-700 ${showFilterDropdown ? 'bg-primary-100 text-primary-700' : ''}`}
            type="button"
          >
           
            Filter
            <svg className="w-5 h-5 -rotate-90 ms-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
            <div
              id="filterDropdown"
              className={`absolute right-0 z-10 ${showFilterDropdown ? 'block' : 'hidden'} bg-white divide-y divide-gray-100 rounded shadow top-10 w-44 dark:bg-gray-700 dark:divide-gray-600`}
            >
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="filterDropdownButton">
                <li>
                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
                    Filter 1
                  </a>
                </li>
                <li>
                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
                    Filter 2
                  </a>
                </li>
              </ul>
            </div>
          </button> */}
        {/* </div> */}
        </div>

        <div className="overflow-x-auto ">
          {
             isLoadingUsers ? <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
             visible={true}
             height="80"
             width="80"
             ariaLabel="blocks-loading"
             wrapperStyle={{}}
             wrapperClass="blocks-wrapper"
             colors={["black"]}
           /></div>:
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-3">
                  User Name
                </th>
                <th scope="col" className="px-4 py-3">
                  email
                </th>
                <th scope="col" className="px-4 py-3">
                  Phone
                </th>
                <th scope="col" className="pe-4 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Table rows */}
              {/* Map your data here and create rows as needed */}
              {
                allUsers ? allUsers.map((user, index)=>(

              <tr key={index} className="border-b dark:border-gray-700">
                <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {user.name}
                </td>
                <td className="px-4 py-3">{user.email}</td>
                <td className="px-4 py-3">{user.phone}</td>
                <td className="px-2 py-3">
                  <ul className="flex items-center gap-3 py-1 text-sm text-gray-700 dark:text-gray-200">
                    <li onClick={()=>openModal(user.id)} className='cursor-pointer'>
                      {/* <a href="#"> */}
                        <img src={'images/sidebar/Show.svg'} alt="Show" />
                      {/* </a> */}
                    </li>
                    
                  </ul>
                </td>
              </tr>
                )):""
              }
              {/* Map more rows here */}
            </tbody>
          </table>
          }
        </div>
      {/* Pagination */}
      <div className="flex justify-center bg-[#8DC67B]">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              forcePage={currentPage - 1}
            />
          </div>
      </div>
      {
        isModalOpen &&
      <div className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 shadow-lg">
         
          <ClickAwayListener onClickAway={toggleModal}>
           
            <div
              // ref={modalRef}
              className="finance-user-modal bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6  relative md:w-[40%] w-[90%] sm:w-[90%] max-h-[80%] overflow-auto"
            >
              <button
                onClick={closeModal}
                className="absolute text-4xl font-semibold top-2 right-2 text-gray-500 hover:text-black dark:text-gray-400"
              >
                &times;
              </button>
              {isSingleUser ? <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div>:
              <div>
                {/* userImage */}
              <div className="userImage mb-2">
                {
                   singleUserData.avater === null ? <RxAvatar size={150}/> : 
                <img
                  className="w-52 rounded-lg"
                  src={
                    singleUserData.avater
                      // ? `${baseUrl}${selectedUserId.profile_picture}`
                      // : ""
                  }
                  alt="not found"
                />
                }
              </div>
              {/* Username */}
                <div className="username mb-2">
                <h2 className="text-lg font-semibold">Full Name</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleUserData?.name}
                </p>
              </div>

              {/* Email */}
              <div className="useremail mb-2">
                <h2 className="text-lg font-semibold">Email</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleUserData?.email}
                </p>
              </div>

               {/* Phone */}
               <div className="userPhone mb-2">
                <h2 className="text-lg font-semibold">Phone</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleUserData?.phone}
                </p>
              </div>

               {/* Address */}
               <div className="useraddress mb-2">
                <h2 className="text-lg font-semibold">Address</h2>
                <p className="shadow-sm p-2 rounded-xl font-medium text-slate-700">
                  {singleUserData?.address}
                </p>
              </div>
              </div>}
              
             

              
             
            </div>
            
          </ClickAwayListener>
         
        </div>
      }
    </div>
  );
}

export default AllUsers