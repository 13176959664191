// LocationContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const LocationContext = createContext();

export const LocationContextProvider = ({ children }) => {
  const [allLocations, setAllLocations] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(20);  
  const [IsLoadingLocation, setIsLoadingLocation] = useState(false);


  const getLocations = async (token, page = currentPage, itemsPerPage = limit, searchQuery = '') => {
    setIsLoadingLocation(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", `/api/v1/locations?page=${page}&per_page=${itemsPerPage}&search=${searchQuery}`, {} , headers);
      if (response && response["success"] === true) {
        setIsLoadingLocation(false);
        setAllLocations(response["data"].data);
        setPageCount(response?.data?.last_page);
        setLimit(response?.data?.per_page);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsLoadingLocation(false);
    }
  };

  const addLocation = async (token, locationData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };
      const response = await ApiTemplate("post", "/api/v1/location", locationData, headers);
      if (response && response["success"] === true) {
        setAllLocations([...allLocations, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };


  const deleteLocation = async (token, locationId) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/remove/location/"+locationId, {}, headers);
      if (response && response["success"] === true) {
        
        await getLocations(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const updateLocation = async (token, locationId, locationData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/update/location/"+locationId, locationData, headers);
      if (response && response["success"] === true) {
        
        await getLocations(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  return (
    <LocationContext.Provider
      value={{
        allLocations,
        getLocations,
        addLocation,
        deleteLocation,
        updateLocation,
        currentPage,
        setCurrentPage,
        pageCount,
        IsLoadingLocation
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const locationContextValue = useContext(LocationContext);
  return locationContextValue;
};
