import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useEventContext } from '../../contexts/EventContextProvider';
import { Link } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

function AllEvent() {

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { token } = useAuthContext();
  const { allEvents,
    getEvents, getEventsBookedUsers, setEventCurrentPage, eventPageCount,eventCurrentPage} = useEventContext();

  const getAllEvents = async () => {
    await getEvents(token);
  }

  useEffect(() => {
    getAllEvents();
  }, []);

  // Function to toggle the Actions dropdown
  const toggleActionsDropdown = () => {
    setShowActionsDropdown(!showActionsDropdown);
  };

  // Function to toggle the Filter dropdown
  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handlePageChange = (page) => {
    const selectedPage = page.selected + 1;
    setEventCurrentPage(selectedPage)
  }

  return (
    <div className='px-5 py-10'>
      {allEvents ? (
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">EVENTS</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
            </div>


          </div>

          <div className="overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-4 py-3">
                    No of Persons
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Start Time
                  </th>
                  <th scope="col" className="px-4 py-3">
                    End Time
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Event users
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {/* Map your data here and create rows as needed */}
                {
                  allEvents && allEvents?.map((event, index) => {
                    return <tr key={index} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {event.title}
                      </td>
                      <td className="px-4 py-3">{event.category.name}</td>
                      <td className="px-4 py-3">{event.total_group_members}</td>
                      <td className="px-4 py-3">{event.price}</td>
                      <td className="px-4 py-3">{event.date_at.split("-")[0]}</td>
                      <td className="px-4 py-3">{event.date_at.split("-")[1]}</td>
                      <td className="px-4 py-3">
                        <Link to={`event-booked-by/${event.id}`}>
                        <button className='bg-[#8DC67B] py-1 px-1 rounded-lg text-white font-semibold'
                      // onClick={()=>getBookingAgainsEvent(event.id)}
                      >Users</button>
                        </Link>
                      </td>
                      <td className="px-2 py-3">
                        <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700 dark:text-gray-200">
                          <li>
                            <Link to={"/event/view/" + event.id} >
                              <img src={'images/sidebar/Show.svg'} alt="Show" />
                            </Link>
                          </li>

                        </ul>
                      </td>
                    </tr>
                  })
                }

                {/* Map more rows here */}
              </tbody>
            </table>
           
          </div>
           {/* Pagination */}
           <div className="flex justify-center bg-[#8DC67B] mt-5 rounded-lg">
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={eventPageCount}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
                forcePage={eventCurrentPage - 1}
              />
            </div>
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div>
        </div>
      )}
    </div>
  );
}

export default AllEvent;
