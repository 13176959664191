// UsersContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const UsersContext = createContext();

export const UsersContextProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(10);  
  const [isLoadingUsers,setIsLoadingUsers] = useState(false);
  const [isSingleUser , setIsIsingleUsers] = useState(false);
  const [singleUserData, setSingleUserData] = useState(null);

  const getUsers = async (page = currentPage, itemsPerPage = limit, searchQuery = '') => {
    const token = JSON.parse(localStorage.getItem('token'));
    setIsLoadingUsers(true);
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/users?page=${page}&limit=${itemsPerPage}&search=${searchQuery}`, {}, headers);
      if (response && response["success"] === true) {
        setAllUsers(response["data"].data);
        setPageCount(response?.data?.last_page)
        setLimit(response?.data?.per_page)
        setIsLoadingUsers(false)
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsLoadingUsers(false)
    }
  };

  const getSingleUser = async (id) => {
    const token = JSON.parse(localStorage.getItem('token'));
    setIsIsingleUsers(true);
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/user/${id}`, {}, headers);
      if (response && response["success"] === true) {
        setSingleUserData(response.data);
        setIsIsingleUsers(false)
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsIsingleUsers(false)
    }
  };

  const addUser = async (token, userData) => {
    try {
      // Implement the logic to add a user using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const deleteUser = async (token, userId) => {
    try {
      // Implement the logic to delete a user using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateUser = async (token, userId, userData) => {
    try {
      // Implement the logic to update a user using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const emptySingleUser = (val) =>{
    setSingleUserData(val)
  }

  useEffect(()=>{
    getUsers()
  },[])

  return (
    <UsersContext.Provider
      value={{
        allUsers,
        getUsers,
        addUser,
        deleteUser,
        updateUser,
        isLoadingUsers,
        currentPage,
        setCurrentPage,
        pageCount,
        getSingleUser,
        isSingleUser,
        singleUserData,
        emptySingleUser
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => {
  const usersContextValue = useContext(UsersContext);
  return usersContextValue;
};
