import React, { useEffect, useState } from 'react';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { useIconsContext } from '../../../contexts/IconsContextProvider';
import { baseUrl } from '../../../apis/base_url';
import Select from 'react-select';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ThreeDots } from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

function EditFeatures({ onNext }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addEventFeatures, addEventId, getEventbyId, singleEventId, editEventFeatures } = useEventContext();
  const { token } = useAuthContext();
  const { allIcons, getIcons } = useIconsContext();
  const [featureList, setFeatureList] = useState([]);
  const navigate = useNavigate();

  const handleAddFeature = () => {
    setFeatureList([...featureList, { icon_id: null, title: '', description: '' }]);
  };

  const handleDeleteFeature = (index) => {
    setFeatureList(featureList.filter((_, i) => i !== index));
  };

  const handleFeatureInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFeatureList = [...featureList];
    updatedFeatureList[index][name] = value;
    setFeatureList(updatedFeatureList);
  };

  const setDataFromSingleEventId = () => {
    if (singleEventId?.features?.length) {
      setFeatureList(
        singleEventId.features.map((feature) => ({
          title: feature.title || "",
          description: feature.description || "",
          feature_id:feature.id || "",
          icon_id: feature.icon?.id || null,
        }))
      );
    }
  };

  useEffect(() => {
    getIcons(token);
    if (id) getEventbyId(token, id);
  }, [id]);

  useEffect(() => {
    setDataFromSingleEventId();
  }, [singleEventId]);

  const options = allIcons?.map((icon) => ({
    value: icon.id,
    label: (
      <div>
        <img src={`${baseUrl}/${icon.path}`} alt="Icon" width={20} height={20} />
      </div>
    ),
    image: `${baseUrl}/${icon.path}`,
  }));

  const handleNext = async () => {
    setLoading(true);
  
    // Validate the feature list
    if (
      featureList.some(
        (feature) => !feature.icon_id || !feature.title.trim() || !feature.description.trim()
      )
    ) {
      setError("Please fill all fields.");
      setLoading(false);
      return;
    }
  
    // Prepare the formatted data
    const formattedData = featureList.map((feature) => ({
      feature_id: feature.feature_id || null, // Use feature_id if it exists, otherwise null
      icon_id: feature.icon_id,
      title: feature.title,
      description: feature.description,
    }));
  
    try {
      const response = await editEventFeatures(
        id,
        JSON.stringify({ features: formattedData, event_id: JSON.parse(id) })
      );
  
      if (!response) {
        setError("Oops! Event not added. Please try again.");
      }
       else {
        navigate(`/event/view/${id}`);
      }
    } catch (error) {
      console.error("Error editing features:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <section className="w-full p-4 rounded-md">
      {allIcons ? (
        <section className="w-full px-10 pt-5 pb-10 rounded-md bg-white">
          {/* <div className="flex space-x-3">
            <h1 className="font-semibold text-md">Edit Features</h1>
          </div> */}
          <div className='flex gap-3 items-center mb-4'>
          <button
            onClick={() => navigate("/event/view/"+id)}
            className=" left-0 flex  items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack
              size={28}
              className="text-black cursor-pointer"
            />
          </button>
          <h1 className='font-semibold text-2xl'>Edit Features</h1>
        </div>
          <div className="flex flex-col gap-6 mt-5">
            {featureList.map((feature, index) => (
              <div key={index} className="flex flex-row gap-5">
                <div className="relative border rounded-md px-2 py-4 w-full">
                  <input
                    type="text"
                    name="title"
                    value={feature.title}
                    onChange={(e) => handleFeatureInputChange(index, e)}
                    className="w-full p-0 border-0 text-base font-normal focus:outline-none"
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">Name</p>
                </div>
                <div className="relative border rounded-md px-2 py-4 w-full">
                  <input
                    type="text"
                    name="description"
                    value={feature.description}
                    onChange={(e) => handleFeatureInputChange(index, e)}
                    className="w-full p-0 border-0 text-base font-normal focus:outline-none"
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">Description</p>
                </div>
                <div className="relative border rounded-md px-2 py-4 w-full">
                  <Select
                    value={options.find((option) => option.value === feature.icon_id) || null}
                    onChange={(value) => {
                      const updatedFeatureList = [...featureList];
                      updatedFeatureList[index].icon_id = value?.value || null;
                      setFeatureList(updatedFeatureList);
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border:"none"
                      }),
                    }}
                    options={options}
                    placeholder=""
                    isClearable
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">Select Icon</p>
                </div>
                {/* {index > 0 && (
                  <RiDeleteBin6Line
                    onClick={() => handleDeleteFeature(index)}
                    size={20}
                    className="cursor-pointer text-red-500"
                  />
                )} */}
              </div>
            ))}
            <div className="flex items-center justify-end w-full space-x-8">
              <button onClick={handleAddFeature} className="border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B]">
                + Add Feature
              </button>
            </div>
            {error && <p className="text-sm font-normal text-center text-red-500">{error}</p>}
            <div className="flex items-center justify-center w-full space-x-8">
              <Link to="/all-events">
                <button className="border px-16 py-2 rounded-md border-black text-[#8DC67B]">
                  Cancel
                </button>
              </Link>
              <button
                onClick={handleNext}
                className="px-16 py-2 rounded-md text-white bg-[#8DC67B] flex justify-center items-center">
                {loading ? <ThreeDots height="20" width="40" color="white" /> : "Save edit"}
              </button>
            </div>
          </div>
        </section>
      ) : (
        <ThreeDots height="50" width="50" color="green" />
      )}
    </section>
  );
}

export default EditFeatures;
