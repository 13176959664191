import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';

function EditAccomodation() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [accommodationFields, setAccommodationFields] = useState([
    { no_of_persons: '', type: '', price: '' },
  ]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const {
    getEventbyId,
    singleEventId,
    editEventAccomodation, // Ensure this is imported from the EventContext
  } = useEventContext();

  const fetchAllEvents = async () => {
    await getEventbyId(token, id);
  };

  const addAccommodationField = () => {
    setAccommodationFields([
      ...accommodationFields,
      { no_of_persons: '', type: '', price: '' },
    ]);
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...accommodationFields];
    updatedFields[index][fieldName] = value;
    setAccommodationFields(updatedFields);
  };

  const removeAccommodationField = (index) => {
    const updatedFields = [...accommodationFields];
    updatedFields.splice(index, 1);
    setAccommodationFields(updatedFields);
  };

  const displayData = async () => {
    setLoading(true);

    const isAnyFieldEmpty = accommodationFields.some(
      (field) =>
        field.no_of_persons === '' || field.type === '' || field.price === ''
    );

    if (isAnyFieldEmpty) {
      setError('Please fill all fields.');
      setLoading(false);
      return;
    }

    // Include `event_id` in each accommodation field
    let updatedAccomodationFields = accommodationFields.map((field) => ({
      ...field,
      id: field.id || null,
                no_of_persons: field.no_of_persons,
                type: field.type,
                price:field.price,
      event_id: JSON.parse(id),
    }));

    try {
      const response = await editEventAccomodation(
        id,
        JSON.stringify({ accomodations: updatedAccomodationFields })
      );

      if (response) {
        navigate(`/event/view/${id}`); // Redirect back to the event view page
      } else {
        setError('Failed to update accommodation details. Please try again.');
      }
    } catch (error) {
      console.error('Error updating accommodation:', error);
      setError('An error occurred while updating accommodation.');
    }

    setLoading(false);
  };

  const setDataFromSingleEventId = () => {
    singleEventId &&
      singleEventId.accomodation &&
      singleEventId.accomodation.length > 0 &&
      setAccommodationFields(singleEventId.accomodation);
  };

  useEffect(() => {
    fetchAllEvents();
  }, [id]);

  useEffect(() => {
    setDataFromSingleEventId();
  }, [singleEventId]);

  return (
    <section className="w-full p-4 rounded-md">
      <section className="w-full px-10 pt-5 pb-10 rounded-md bg-white">
        {/* Title */}
        <div className="flex gap-3 items-center mb-4">
          <button
            onClick={() => navigate(`/event/view/${id}`)}
            className="left-0 flex items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack size={28} className="text-black cursor-pointer" />
          </button>
          <h1 className="font-semibold text-2xl">Edit Accommodation</h1>
        </div>
        {/* Form */}
        <div className="flex flex-col space-y-6 mt-5">
          <div id="accommodationFields">
            {accommodationFields.map((field, index) => (
              <div
                key={index}
                className="flex items-center w-full space-x-8 mb-5"
              >
                <div className="relative border rounded-md border-black px-2 py-4 w-1/3">
                  <input
                    type="text"
                    value={field.no_of_persons}
                    className="w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                    onChange={(e) =>
                      handleFieldChange(index, 'no_of_persons', e.target.value)
                    }
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">
                    No. of Persons
                  </p>
                </div>
                <div className="relative border rounded-md border-black px-2 py-4 w-1/3">
                  <input
                    type="text"
                    value={field.type}
                    className="w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                    onChange={(e) =>
                      handleFieldChange(index, 'type', e.target.value)
                    }
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">Type</p>
                </div>
                <div className="relative border rounded-md border-black px-2 py-4 w-1/3">
                  <input
                    type="text"
                    value={field.price}
                    className="w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
                    onChange={(e) =>
                      handleFieldChange(index, 'price', e.target.value)
                    }
                  />
                  <p className="absolute -top-3 left-5 bg-white px-2">Price</p>
                </div>
                <IoMdClose
                  size={24}
                  className="cursor-pointer text-gray-500 hover:text-red-500"
                  onClick={() => removeAccommodationField(index)}
                />
              </div>
            ))}
          </div>
          {/* Add Accommodation Button */}
          <div className="flex items-center justify-end w-full space-x-8">
            <button
              className="border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B] text-md font-normal"
              onClick={addAccommodationField}
            >
              + Add Accommodation
            </button>
          </div>

          {error && (
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>
          )}

          {/* Cancel and Save Buttons */}
          <div className="flex items-center justify-center w-full space-x-8">
            <Link to="/all-events">
              <button className="border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold">
                Cancel
              </button>
            </Link>
            <button
              onClick={displayData}
              className="px-16 py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold"
            >
              {loading ? (
                <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </section>
    </section>
  );
}

export default EditAccomodation;
