import React, { useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ThreeDots } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';

function AddPlans({ onNext }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [eventPlan, setEventPlan] = useState(null);
  const [title, setTitle] = useState(null);
  const [day, setDay] = useState('');
  const navigate = useNavigate();

  const { addEventPlans, addEventId } = useEventContext()
  const { token } = useAuthContext();



  const options = {
    morning: "Morning",
    lunch_break: "Lunch Break",
    afternoon: "Afternoon",
    evening: "Evening",
    dinner: "Dinner",
    night_time: "Night Time"
  }



  const onTitleChange = (e) => {
    let value = e.target.value
    console.log(value)
    let plan = {
      [e.target.value]: [{ from_time: '', to_from: '', description: '' }]
    }
    if (!eventPlan) {
      setEventPlan({ ...plan })
    }
    else {
      setEventPlan({ ...eventPlan, ...plan })
    }
    console.log(eventPlan)
  }

  const onItemValueChange = (newValue, key, index, field) => {
    const updatedPlan = { ...eventPlan };
    updatedPlan[key][index][field] = newValue;
    setEventPlan(updatedPlan);
  };

  const onItemValueDelete = (key, index) => {
    const updatedPlan = { ...eventPlan };
    updatedPlan[key].splice(index, 1);
    setEventPlan(updatedPlan);
  };

  const addTimeAndDescription = (key) => {
    setEventPlan({ ...eventPlan, [key]: [...eventPlan[key], { from_time: '', to_from: '', description: '' }] })
  }

  const onSaveClick = async () => {
    setLoading(true);
    if (!day || Object.keys(eventPlan).length === 0 || !Object.values(eventPlan).every(plan => plan.length > 0)) {
      setError('Please fill in all fields before saving.');
      setLoading(false);
      return;
    }
    if (!addEventId) {
      setError('Please add event first.');
      setLoading(false);
      return;
    }

    const response = await addEventPlans(token, JSON.stringify({ event_id: addEventId, day_date: day, ...eventPlan }));
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    navigate("/all-events")
    setEventPlan(null)
    setDay("");
    setTitle(null);   
    setLoading(false);
    
  }

  return (
    <section className='w-full p-4 rounded-md  '>
      <section className='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
        {/* {{-- title --}} */}
        <div className='flex space-x-3'>
          <h1 className='font-semibold text-md'>Add Event Plan</h1>
        </div>

        {/* {{-- form  --}} */}
        <div className='flex flex-col space-y-6 mt-5'>
          {/* {{-- start date and end date --}} */}

          {/* {{--  select date , title , start time, end time  --}} */}
          <div className='flex items-center w-full space-x-8'>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='date'
                value={day}
                onChange={(e) => setDay(e.target.value)}
                className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p className='absolute -top-3 left-5 bg-white px-2'>
                Select Day
              </p>
            </div>
            <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select value={title} onChange={onTitleChange} className='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Category
                </option>
                {
                  Object.entries(options).map(([key, value]) => {
                    return <option disabled={eventPlan !== null ? Object.keys(eventPlan).includes(key) ? true : false : false} value={key}>{value}</option>
                  })
                }

              </select>
              <p className='absolute -top-3 left-5 bg-white px-2'>Title</p>
            </div>

          </div>
          {
            eventPlan && Object.entries(eventPlan).map(([key, value]) => {
              return <div className="flex flex-col gap-4 w-full   p-3 border rounded-md shadow-md">
                <h1 className="text-black font-bold text-base">{options[key]}</h1>
                <div className="flex flex-col gap-4 justify-end">
                  {value.map((item, index) => {
                    console.log(index)
                    return <div key={index} className="flex gap-5 items-center justify-center">
                      {/* start time */}
                      <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
                        <input
                          type='time'
                          name="from_time"
                          value={item["from_time"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "from_time")}
                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>Start Time</p>
                      </div>
                      {/* end time */}
                      <div className='relative border rounded-md border-black px-2 py-4 w-1/2'>
                        <input
                          type='time'
                          name="to_from"
                          value={item["to_from"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "to_from")}

                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>End Time</p>
                      </div>
                      {/* description */}
                      <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                        <input
                          type='text'
                          name="description"
                          value={item["description"]}
                          onChange={(e) => onItemValueChange(e.target.value, key, index, "description")}
                          className='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                        />
                        <p className='absolute -top-3 left-5 bg-white px-2'>Description</p>
                      </div>
                      {index !== 0 && ( // Check if it's not the first row
                        <div className='relative  px-2 py-10 flex items-center justify-center '>
                          <RiDeleteBin6Line
                            onClick={() => onItemValueDelete(key, index)}
                            size={20}
                            className='absolute right-2 top-2 cursor-pointer text-red-500'
                          />
                        </div>
                      )}
                    </div>
                  })}
                  <div className='flex items-center justify-end  w-full space-x-8'>
                    <button onClick={() => addTimeAndDescription(key)} className='border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B] text-md font-normal'>
                      Add
                    </button>
                  </div>
                </div>

              </div>
            })
          }
          {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}
          {/* save button */}
          <div className='flex items-end justify-end  w-full space-x-8'>
            <button onClick={onSaveClick}
              className=' px-16 py-2 rounded-md w-max  text-white bg-[#8DC67B] text-md font-semibold'
            >
              {loading
                ? <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
                : 'Save'}
            </button>
          </div>



          {/* {{-- cancel and next button --}} */}
          {/* <div className='flex items-center justify-center  w-full space-x-8'>
            <Link to={"/all-events"} >
              <button className=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'>
                Submit
              </button>
            </Link>
           
          </div> */}
        </div>

        {/* </div> */}
      </section>
    </section>
  )
}

export default AddPlans
