// CategoriesContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const CategoriesContext = createContext();

export const CategoriesContextProvider = ({ children }) => {
  const [allCategories, setAllCategories] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(20);  
  const [isLoadingCategory,setIsLoadingCategory] = useState(false);

  const getCategories = async (token, page = currentPage, itemsPerPage = limit, searchQuery = '') => {
    setIsLoadingCategory(true);
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", `/api/v1/categories?page=${page}&per_page=${itemsPerPage}&search=${searchQuery}`, {}, headers);
      if (response && response["success"] === true) {
        setAllCategories(response["data"].data);
        setPageCount(response?.data?.last_page);
        setLimit(response?.data?.per_page);
        setIsLoadingCategory(false);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsLoadingCategory(false);
    }
  };

  const addCategory = async (token, categoryData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/category", categoryData, headers);
      if (response && response["success"] === true) {
        setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteCategory = async (token, categoryId) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/remove/category/"+categoryId, {}, headers);
      if (response && response["success"] === true) {
        
        await getCategories(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const updateCategory = async (token, categoryId, categoryData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/update/category/"+categoryId, categoryData, headers);
      if (response && response["success"] === true) {
        
        await getCategories(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        allCategories,
        getCategories,
        addCategory,
        deleteCategory,
        updateCategory,
        isLoadingCategory,
        currentPage,
        setCurrentPage,
        pageCount,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => {
  const categoriesContextValue = useContext(CategoriesContext);
  return categoriesContextValue;
};
